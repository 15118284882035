.ant-input:focus {
  border-color: #f76617;
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px #f76617;
  box-shadow: 0 0px 4px 1px #f76617;
  /* border: 2px solid red; */
}

.ant-picker-panels > *:first-child button.ant-picker-header-next-btn {
  visibility: visible !important;
}

.ant-picker-panels > *:first-child button.ant-picker-header-super-next-btn {
  visibility: visible !important;
}

.ant-picker-panels > *:last-child {
  display: none;
}

.ant-picker-panel-container,
.ant-picker-footer {
  width: 280px !important;
}

.ant-picker-footer-extra > div {
  flex-wrap: wrap !important;
}

.custom-pagination .ant-pagination-total-text {
  font-size: 14px; /* Set your desired font size */
}

/* Style for WebKit-based browsers (Chrome, Edge, Safari) */
::-webkit-scrollbar {
  width: 5px; /* Set the width of the scrollbar */
}

::-webkit-scrollbar-track {
  background: #f1f1f1; /* Light grey background for the scrollbar track */
}

::-webkit-scrollbar-thumb {
  background: grey; /* Grey color for the scrollbar */
  border-radius: 5px; /* Make the scrollbar thumb rounded */
}

::-webkit-scrollbar-thumb:hover {
  background: darkgrey; /* Slightly darker grey when hovering over the scrollbar */
}

/* Style for Firefox */
html {
  scrollbar-width: thin; /* Thin scrollbar */
  scrollbar-color: rgb(206, 206, 206) #f1f1f1; /* Grey thumb on light grey track */
}

.custom-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: rgb(206, 206, 206) #f1f1f1;
}

.custom-scrollbar::-webkit-scrollbar {
  height: 5px; /* Horizontal scrollbar */
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 5px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
}

*,
html,
body {
  font-family: "Inter";
}

.main-content-page {
  align-items: center;
  padding-top: 1rem; /* pt-3 */
  padding-bottom: 0.5rem; /* pb-2 */
  padding-left: 1rem; /* part of px-3 */
  padding-right: 1rem; /* part of px-3 */
  width: 100%; /* w-100 */
}
